import React from "react";

function About() {
    return (
        <div className="about">
            <div class="container">
                <div class="row align-items-center my-5">
                    <div class="col-lg-7">
                        <img
                            class="img-fluid rounded mb-4 mb-lg-0"
                            src="img/About.jpg"
                            alt=""
                        />
                    </div>
                    <div class="col-lg-5">
                        <h1 class="font-weight-light">Services</h1>
                        <p>
                            We provide extraordinary services and solutions to government agengies and other clients. Our areas of expertise include Project Management, Application Development, Web Development, Public Health Data Processing, Systems DevOps, Database Administration, and Application Support.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;